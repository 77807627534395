











import { Inject } from 'inversify-props';
import { Mixins, Component } from 'vue-property-decorator';
import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import MarketsFiltersService, { MarketsFiltersServiceS } from '../markets-filters.service';
import MarketsService, { MarketsServiceS } from '../markets.service';
import { DownloadExcelForm } from '../markets-api.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class MarketsDownloadExcelModal extends Mixins(DownloadReportControlMixin) {
    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(MarketsFiltersServiceS)
    private marketsFiltersService!: MarketsFiltersService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(MarketsServiceS)
    private marketsService!: MarketsService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    private isLoading = false;

    public get attrs() {
        const { customColumns, filters } = this;
        const { properties, isLoading } = this;
        const { buttons } = this;

        return {
            dataType: 'Visibility',
            customColumns,
            filters,
            properties,
            buttons,
            isLoading,
        };
    }

    public async requestReport(form: DownloadExcelForm, toEmail?: boolean) {
        const month = form.date!.getMonth();
        const year = form.date!.getFullYear();
        const settings = {
            ...form,
            month,
            year,
            providers: form.choosedProviders.map(p => p.value),
            date: undefined,
        };

        this.isLoading = true;
        const providersWithoutReports = await this.marketsService
            .downloadExcel(settings, toEmail);

        if (providersWithoutReports.length) {
            const providerNames = providersWithoutReports
                .map(p => this.providersService.getProviderLabel(p))
                .join(', ');

            this.triggerFormError(`There is no data for ${providerNames}. Try change the filters.`);
        } else if (!toEmail) {
            this.closeForm();
        } else {
            this.triggerFormMessage('Reports sent');
        }

        this.isLoading = false;
    }

    private get properties() {
        const { month, year } = this.documentFiltersService;

        return [
            {
                label: 'Date',
                key: 'date',
                default: new Date(year, month),

                component: MonthPicker,
                props: {
                    locale: this.$i18n.locale,
                },
            },
        ];
    }

    private get customColumns() {
        return [];
    }

    private get compsetOptions() {
        return (this.compsetsService.compsets || [])
            .map(compset => ({
                name: compset.name,
                value: compset.id,
            }));
    }

    private get losOptions() {
        return DEFAULT_LOS.map((los: number) => ({
            value: los,
            name: String(los),
        }));
    }

    private get posOptions() {
        return this.documentFiltersService.posMarketItems;
    }

    private get sourceOptions() {
        const sourceList = new Set(
            (this.compsetsService.compsets || [])
                .map(compset => compset.marketProviders)
                .flat(),
        );

        return Array.from(sourceList)
            .map(provider => ({
                value: provider,
                name: this.providersService.getProviderLabel(provider),
                disabled: false,
            }));
    }

    private get filters() {
        const { id: currentCompsetId = null } = this.compsetsService.currentCompset || {};
        const { pos } = this.marketsFiltersService;

        const defaultSource = this.sourceOptions[0];

        return [
            {
                label: 'Comp Set',
                options: this.compsetOptions,
                key: 'compsetId',
                default: currentCompsetId,
            },
            {
                label: 'Source',
                options: this.sourceOptions,
                key: 'choosedProviders',
                default: defaultSource ? [defaultSource] : [],
                multiselect: true,
            },
            {
                label: 'LOS',
                options: this.losOptions,
                key: 'los',
                default: 1,
            },
            {
                label: 'POS',
                options: this.posOptions,
                key: 'pos',
                default: pos,
            },
        ];
    }

    private get buttons() {
        return [
            {
                label: 'Download now',
                onClick: this.requestReport.bind(this),
            },
        ];
    }
}
